import { CSSProperties } from 'react'

import { LightTheme } from '@src/themes'

export const selectedLeftBorderStyle: CSSProperties = {
  borderLeft: `0.3em solid ${LightTheme.palette.themePrimary}`,
  paddingLeft: '0.8em',
}

export const unselectedLeftBorderStyle: CSSProperties = {
  borderLeft: '0.1em solid lightgray',
  paddingLeft: '1em',
}
