import { FacetResult, Resource, SearchResult } from '@blaw/contracts-api-schema'
import { FilterState } from '@contexts/KeyTermsContext'
import usePagination, { PaginationComponent, PaginationMeta } from '@hooks/usePagination'
import {
  createContext,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'

import { PlaybooksSearchResults } from '@fixtures/playbooks'
import { FacetLabel, FacetListItem } from '@modules/ClauseAnalyzer'
import { t } from 'i18next'

interface Props {
  children?: any
  fetchingItemsDefault?: boolean
}

export interface PlaybooksContextState {
  query: string
  setQuery: Dispatch<SetStateAction<string>>
  error?: string
  setError: Dispatch<SetStateAction<string | undefined>>
  warn: string
  Pagination: PaginationComponent
  pageNum: number
  topOfPageRef: MutableRefObject<HTMLDivElement | null>
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  playbooks: Resource[]
  setPlaybooks: Dispatch<SetStateAction<Resource[]>>
  fetchPlaybooks: (query: string, page: number, filters: FilterState) => Promise<PlaybooksResult>
  loadPage: (query: string) => void
  loadNextPage: (page: number) => void
  loadFirstPage: (...args: any) => Promise<void>
  fetchingItems: MutableRefObject<boolean>
}

const playbooksPerPage = 20

type PlaybooksResult = {
  playbooks: Resource[]
  meta: PaginationMeta
  items: {
    facets: FacetResult[]
    count: number
  }
  warn?: string
  error?: string
}

type PlaybooksResponse = {
  facets: FacetListItem[]
  count: number
}

export const PlaybooksContext = createContext({} as PlaybooksContextState)

function PlaybooksContextProvider(props: Props) {
  const [query, setQuery] = useState('')
  const [error, setError] = useState<string>()
  const [warn, setWarn] = useState('')
  const [loading, setLoading] = useState(true)
  const [playbooks, setPlaybooks] = useState<Resource[]>([])
  const [, setMeta] = useState<PaginationMeta>()
  const [count, setCount] = useState<number>(0)
  const fetchingItems = useRef(props.fetchingItemsDefault ?? false)

  const { loadFirstPage, Pagination, pageNum } = usePagination({
    loadPage,
    loadNextPage,
    hitsPerPage: playbooksPerPage,
    scrollToTop: () => topOfPageRef.current && topOfPageRef.current.scrollIntoView(),
  })

  useEffect(() => {
    loadFirstPage(query)
  }, [query])

  const topOfPageRef = useRef<null | HTMLDivElement>(null)

  // TODO: Call services API to fetch playbooks
  async function fetchPlaybooks(query: string, page: number) {
    const result: PlaybooksResult = {
      playbooks: [],
      meta: { totalPages: 0, totalHits: 0 },
      items: { facets: [], count: 0 },
    }
    const playbooks = (PlaybooksSearchResults.results as SearchResult[]).map(searchResultToResource)
    const meta = { totalPages: 1, totalHits: 2 }
    if (playbooks?.length) {
      setPlaybooks(playbooks)
      setMeta(meta)
      setCount(3)
      result.meta = meta
      result.playbooks = playbooks
    } else {
      setCount(0)
      result.warn = t('page.Contracts.No Results Warning')
    }
    return result
  }

  function searchResultToResource(searchResult: SearchResult): Resource {
    const { metadata } = searchResult
    return {
      id: searchResult.documentId,
      name: searchResult.documentName,
      mimeType: searchResult.mimeType,
      resourceType: 'template_document',
      author: searchResult.modifiedBy || metadata.owner?.value,
      links: { self: '', metadata: '', content: '' },
      updated: metadata.last_updated_date?.value || '',
      created: searchResult.dateCreated,
      metadata: {
        userMetadata: {
          contract_type: metadata.contract_type?.value,
          description: metadata.description?.value,
        },
        systemMetadata: {},
        customMetadata: { digests: searchResult.digests },
      },
    } as Resource
  }

  async function loadPage(query: string) {
    setError('')
    setWarn('')
    setLoading(true)
    fetchingItems.current = true

    const { playbooks, meta, error, warn } = await fetchPlaybooks(query, 0)

    fetchingItems.current = false
    setLoading(false)
    if (warn) setWarn(warn)
    if (error) setError(error)
    if (!playbooks.length) return {}

    return meta
  }

  async function loadNextPage(page: number) {
    setError('')
    setWarn('')

    return
  }

  const value = {
    query,
    setQuery,
    error,
    setError,
    warn,
    Pagination,
    pageNum,
    topOfPageRef,
    loading,
    setLoading,
    playbooks,
    setPlaybooks,
    fetchPlaybooks,
    loadPage,
    loadNextPage,
    loadFirstPage,
    fetchingItems,
  }

  return <PlaybooksContext.Provider value={value}>{props.children}</PlaybooksContext.Provider>
}

export default PlaybooksContextProvider
