import { MetadataTypes } from '@blaw/contracts-api-schema'
import { GetObligationsResponse } from '@pages/AddObligations/AddObligations'

export const mockExtractedObligations: GetObligationsResponse = {
  obligations: {
    'OB-001-EFFECTIVE-DATE': [
      {
        is_deleted: false,
        code: 'OB-001-EFFECTIVE-DATE',
        value: 'Value for Effective Date',
        status: '',
        type: '',
        data_type: '',
        offsets: [],
        reference: 'Reference for Effective Date',
        schema_key: 'effective_date',
        schema_type: MetadataTypes.DATE,
        schema_label: 'Effective Date',
      },
    ],
    CONTRACT_TYPE: [
      {
        is_deleted: false,
        code: 'CONTRACT_TYPE',
        value: 'Value for CONTRACT TYPE',
        status: '',
        type: '',
        data_type: '',
        offsets: [],
        reference: 'Reference for Contract Type',
        schema_key: 'contract_type',
        schema_type: MetadataTypes.TEXT,
        schema_label: 'Contract Type',
      },
    ],
    'OB-013-DEFINITION-OF-CONFIDENTIAL-INFORMATION': [
      {
        is_deleted: false,
        code: 'OB-013-DEFINITION-OF-CONFIDENTIAL-INFORMATION',
        value: 'Value for Confidential Information',
        status: '',
        type: '',
        data_type: '',
        offsets: [],
        reference: 'Reference for Confidential Information',
        schema_key: 'confidential_info',
        schema_type: MetadataTypes.TEXT,
        schema_label: 'Confidential Information',
      },
    ],
    'OB-010-KEY-PAYMENT-DATE': [
      {
        is_deleted: false,
        code: 'OB-010-KEY-PAYMENT-DATE',
        value:
          'as soon as administratively practicable (and in any event, within twenty (20) days) following the Effective Date',
        status: 'EXTRACTED',
        type: 'suggestion',
        data_type: 'UNSTRUCTURED',
        offsets: [['89', '201']],
        reference:
          '(a)An aggregate gross amount equal to $857,000, less applicable withholdings, to be paid as soon as administratively practicable (and in any event, within twenty (20) days) following the Effective Date;',
        schema_key: 'key_payment_date',
        schema_type: MetadataTypes.DATE,
        schema_label: 'Key Payment Date',
      },
    ],
    'OB-027-INDEMNIFICATION': [
      {
        is_deleted: false,
        code: 'OB-027-INDEMNIFICATION',
        value:
          "This Release also does not waive any right or claim that cannot lawfully be waived, any claim for indemnification or coverage under the Company's insurance plans, any right to advancement or indemnification, including under the Company's Bylaws or Certificate of Incorporation and under Section 2.5 of the Amended Employment Agreement, any other rights under the Company's Bylaws or Certification of Incorporation, any rights as a director of the Company, or any rights as a stockholder of the Company.",
        status: 'EXTRACTED',
        type: 'suggestion',
        data_type: 'UNSTRUCTURED',
        offsets: [['1310', '1812']],
        reference:
          'Rights Reserved: This Release does not prevent you from pursuing any workers\' compensation or unemployment insurance benefits to which you may be entitled. This Release does not prevent you from filing an administrative charge or participating in an investigation before any governmental agency charged with enforcement of any law including, but not limited to, the Equal Employment Opportunity Commission, any similar state or local agency, a state or local commission on human rights (collectively, the "EEOC"), the National Labor Relations Board or the Securities and Exchange Commission. You do agree, however, that by signing this Release, you waive any right to recover monetary damages or other individual relief in connection with any such charge you file or investigation in which you participate with the EEOC, however, you may recover any reward or bounty that may be payable as a result of participating in the whistleblower program of the Securities and Exchange Commission or the Occupational Safety and Health Administration or otherwise as required by applicable law. This Release does not waive your rights to continuing health coverage pursuant to the provisions of COBRA orbenefits accrued and vested as of your Separation Date under the Company\'s and its affiliates employee benefit',
        schema_key: 'indemnification',
        schema_type: MetadataTypes.BOOLEAN,
        schema_label: 'Indemnification',
      },
    ],
  },
}

export const mockFormattedExtractedObligations = [
  {
    code: 'OB-012-AUTO-RENEW',
    data_type: '',
    is_deleted: false,
    type: '',
    value: 'Value for Auto Renew',
    status: '',
    text_block: 'Text Block for Auto Renew',
    label: '',
    schema_key: 'auto_renew',
    schema_type: 'BOOLEAN',
    schema_label: 'Auto Renew',
    active: false,
    key: 'auto_renew',
  },
  {
    code: 'OB-009-KEY-PAYMENT-AMOUNT',
    data_type: '',
    is_deleted: false,
    type: '',
    value: 'Value for Key Payment Amount',
    status: '',
    text_block: 'Text Block for Key Payment Amount',
    label: '',
    schema_key: 'key_payment_amount',
    schema_type: 'CURRENCY',
    schema_label: 'Key Payment Amount',
    active: false,
    key: 'key_payment_amount',
  },
  {
    code: 'OB-010-KEY-PAYMENT-DATE',
    data_type: '',
    is_deleted: false,
    type: '',
    value: 'Value for Key Payment Date',
    status: '',
    text_block: 'Text Block for Key Payment Date',
    label: '',
    schema_key: 'key_payment_date',
    schema_type: 'DATE',
    schema_label: 'Key Payment Date',
    active: false,
    key: 'key_payment_date',
  },
  {
    code: 'OB-006-KEY-TERM-DURATION',
    data_type: '',
    is_deleted: false,
    type: '',
    value: 'Value for Contract Duration',
    status: '',
    text_block: 'Text Block for Contract Duration',
    label: '',
    schema_key: 'key_term_duration',
    schema_type: 'DURATION_MONTHS',
    schema_label: 'Contract Duration',
    active: false,
    key: 'key_term_duration',
  },
  {
    code: 'OB-041-RENEWAL-NOTICE-DURATION',
    data_type: '',
    is_deleted: false,
    type: '',
    value: 'Value for Renewal Notice Duration',
    status: '',
    text_block: 'Text Block for Renewal Notice Duration',
    label: '',
    schema_key: 'renewal_notice_duration',
    schema_type: 'DURATION_DAYS',
    schema_label: 'Renewal Notice Duration',
    active: false,
    key: 'renewal_notice_duration',
  },
  {
    code: 'OB-013-DEFINITION-OF-CONFIDENTIAL-INFORMATION',
    data_type: '',
    is_deleted: false,
    type: '',
    value: 'Value for Confidential Information',
    status: '',
    text_block: 'Text Block for Confidential Information',
    label: '',
    schema_key: 'confidential_info',
    schema_type: 'TEXT',
    schema_label: 'Confidential Information',
    active: false,
    key: 'confidential_info',
  },
  {
    code: 'GOVERNING_LAW',
    data_type: '',
    is_deleted: false,
    type: '',
    value: 'Value for Governing Law',
    status: '',
    text_block: 'Text Block for Governing Law',
    label: '',
    schema_key: 'governing_law',
    schema_type: 'CHOICE_TEXT',
    schema_label: 'Governing Law',
    active: false,
    key: 'governing_law',
  },
]
