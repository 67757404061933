import { SearchResult } from '@blaw/contracts-api-schema'

export const PlaybooksSearchResults = {
  totalHits: 2,
  facets: [
    {
      name: 'owner',
      label: 'Owner',
      type: 'TEXT',
      values: [
        {
          label: 'Xinying Zheng',
          value: '15061541',
          selected: false,
          count: 4,
        },
        {
          label: 'Ivan Joukov',
          value: '17843929',
          selected: false,
          count: 8,
        },
        {
          label: 'Nadav Zorman',
          value: '23186066',
          selected: false,
          count: 1,
        },
        {
          label: 'Jeffrey Mezic',
          value: '23186078',
          selected: false,
          count: 4,
        },
        {
          label: 'Ryan Powers',
          value: '26422355',
          selected: false,
          count: 2,
        },
        {
          label: 'Audrey Jones',
          value: '30140391',
          selected: false,
          count: 3,
        },
        {
          label: 'Sean Nordquist',
          value: '30789313',
          selected: false,
          count: 8,
        },
        {
          label: '31149544',
          value: '31149544',
          selected: false,
          count: 4,
        },
        {
          label: 'Jaeyoung Park',
          value: '31567636',
          selected: false,
          count: 1,
        },
        {
          label: 'Mab Benedetto',
          value: '31608941',
          selected: false,
          count: 7,
        },
        {
          label: 'Diego Perez',
          value: '31790475',
          selected: false,
          count: 24,
        },
        {
          label: 'BLAW CM Auto Test User',
          value: '31828564',
          selected: false,
          count: 2,
        },
        {
          label: 'None Provided',
          value: 'undefined',
          selected: false,
        },
      ],
    },
    {
      name: 'contract_type',
      label: 'Contract Type',
      type: 'TEXT',
      values: [
        {
          label: 'License Agreements',
          value: 'License Agreements',
          selected: false,
          count: 9,
        },
        {
          label: 'NDA',
          value: 'NDA',
          selected: false,
          count: 6,
        },
        {
          label: 'Distribution Agreements',
          value: 'Distribution Agreements',
          selected: false,
          count: 4,
        },
        {
          label: 'Consulting Agreements 2',
          value: 'Consulting Agreements 2',
          selected: false,
          count: 1,
        },
        {
          label: 'Miscellaneous Templates',
          value: 'Miscellaneous Templates',
          selected: false,
          count: 1,
        },
        {
          label: 'Non-solicitation Agreements',
          value: 'Non-solicitation Agreements',
          selected: false,
          count: 1,
        },
        {
          label: 'None Provided',
          value: 'undefined',
          selected: false,
        },
      ],
    },
  ],
  results: [
    {
      documentId: '169737451641736229509195295886762193756',
      parentId: '',
      description: 'dfgdf',
      documentName: 'Non-Disclosure Agreement Playbook',
      parentName: '',
      dateModified: '2024-04-01T20:32:02.658',
      modifiedBy: 'Diego Perez',
      digests: [],
      mimeType: 'application/pdf',
      counterParty: [],
      contractType: '',
      contractStatus: '',
      contractCategory: '',
      clientName: [],
      companyStakeholder: [],
      uploadedBy: '',
      contractOrigin: '',
      transaction: '',
      contractOwner: '31790475',
      contractId: '',
      custom_field_1: '',
      customField2: '',
      customField3: '',
      dateCreated: '',
      clauseText: '',
      clauseType: '',
      notesField: '',
      riskLevel: '',
      title: '',
      dealSide: '',
      department: '',
      city: '',
      state: '',
      region: '',
      renewalDate: '',
      renewalTerm: '',
      terminationNoticeDate: '',
      termEndDate: '',
      canceledBy: '',
      metadata: {
        description: {
          type: 'TEXT',
          label: 'Contract Description',
          value: 'dfgdf',
        },
        owner: {
          type: 'TEXT',
          label: 'Owner',
          value: '31790475',
        },
        last_updated_date: {
          type: 'DATE',
          label: 'Last Modified',
          value: '2024-04-01T20:32:02.658',
        },
        name: {
          type: 'TEXT',
          label: 'Contract Name',
          value: 'Non-Disclosure Agreement Playbook',
        },
        delete_groups: {
          type: 'MULTI_TEXT',
          label: '',
          value: ['gu31790475'],
        },
        search_groups: {
          type: 'MULTI_TEXT',
          label: '',
          value: ['gu31790475'],
        },
      },
    },
    {
      documentId: '245979121895901060560224838715489687314',
      parentId: '',
      description: '',
      documentName: 'Playbook - Software License Agreement',
      parentName: '',
      dateModified: '2024-04-01T14:10:50.580',
      modifiedBy: 'Ivan Joukov',
      digests: [],
      mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      counterParty: [],
      contractType: '',
      contractStatus: '',
      contractCategory: '',
      clientName: [],
      companyStakeholder: [],
      uploadedBy: '',
      contractOrigin: '',
      transaction: '',
      contractOwner: '17843929',
      contractId: '',
      custom_field_1: '',
      customField2: '',
      customField3: '',
      dateCreated: '',
      clauseText: '',
      clauseType: '',
      notesField: '',
      riskLevel: '',
      title: '',
      dealSide: '',
      department: '',
      city: '',
      state: '',
      region: '',
      renewalDate: '',
      renewalTerm: '',
      terminationNoticeDate: '',
      termEndDate: '',
      canceledBy: '',
      metadata: {
        owner: {
          type: 'TEXT',
          label: 'Owner',
          value: '17843929',
        },
        last_updated_date: {
          type: 'DATE',
          label: 'Last Modified',
          value: '2024-04-01T14:10:50.580',
        },
        name: {
          type: 'TEXT',
          label: 'Contract Name',
          value: 'Playbook - Software License Agreement',
        },
        delete_groups: {
          type: 'MULTI_TEXT',
          label: '',
          value: ['gu17843929'],
        },
        search_groups: {
          type: 'MULTI_TEXT',
          label: '',
          value: ['gu17843929'],
        },
      },
    },
  ],
}
