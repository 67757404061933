import { CSSProperties } from 'react'
import { List, mergeStyleSets, NeutralColors } from '@fluentui/react'
import { t } from 'i18next'

import BoldText from '@baseComponents/BoldText'
import { StyledDivider } from '@baseComponents/StyledDivider'
import { Resource } from '@blaw/contracts-api-schema'
import ActionsMenu from '@components/ActionsMenu'
import BoldField from '@components/BoldField'
import LoadingShimmer from '@components/LoadingShimmer'
import QuickMessage from '@components/QuickMessage'
import { friendlyDateTime } from '@modules/utils'
import { DISPLAYED_RESOURCETYPES } from '@src/data/displayedResourceTypes'

type Props = {
  resources: Resource[]
  loading?: boolean
  error?: string | null
  style?: CSSProperties
  onRenderResource?: (item?: Resource) => JSX.Element | null
}

const playbookActions = [
  {
    key: 'comply',
    text: 'Check Compliance',
    iconProps: { iconName: 'WaitlistConfirm' },
  },
  {
    key: 'download',
    text: 'Download',
    iconProps: { iconName: 'Download' },
  },
  {
    key: 'edit',
    text: 'Edit',
    iconProps: { iconName: 'PageEdit' },
  },
  {
    key: 'delete',
    text: 'Delete',
    iconProps: { iconName: 'Delete' },
  },
]

export default function PlaybooksList({ loading, error, resources, style }: Props) {
  if (loading) return <LoadingShimmer />
  if (error) return <QuickMessage msg={error} type="error" />

  return (
    <div>
      <List
        onShouldVirtualize={() => false}
        items={resources}
        onRenderCell={item => onRenderResource(item)}
      />
    </div>
  )

  function onRenderResource(item?: Resource) {
    if (!item) return null
    if (!DISPLAYED_RESOURCETYPES.includes(item.resourceType)) return null
    return (
      <>
        <div
          style={{
            padding: '0.5em 0 1em 0',
            ...style,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <BoldText style={{ display: 'flex', alignItems: 'center' }}>{item.name}</BoldText>

            <div className={additionalStyles.hoverStyle}>
              <ActionsMenu
                items={playbookActions}
                onItemClick={() => null}
                iconStyles={{
                  border: `1px solid ${NeutralColors.gray60}`,
                  borderRadius: '0.2em',
                  padding: '0.1em 0.2em',
                }}
              />
            </div>
          </div>
          <BoldField
            label={`${t('label.ContractMetadata.last-modified')}: `}
            value={item.updated ? `${friendlyDateTime(item.updated)}` : ''}
          />
        </div>
        <StyledDivider />
      </>
    )
  }
}

const additionalStyles = mergeStyleSets({
  hoverStyle: {
    selectors: {
      i: {
        ':hover': {
          background: NeutralColors.gray30,
        },
      },
    },
  },
})
