import { FC, useEffect, useState } from 'react'
import { mergeStyles } from '@fluentui/react'
import { Routes, Route, useNavigate, NavigateFunction } from 'react-router-dom'

import '@src/i18n/init'
import Progress from '@components/Progress'
import Home from '@pages/Home'
import PracticalGuidance from '@pages/PracticalGuidance'
import Contracts from '@pages/Contracts'
import ContractView from '@pages/ContractView'
import ContractDetect from '@pages/ContractDetect'
import SaveNewVersion from '@pages/SaveNewVersion'
import ClauseLibrary from '@pages/ClauseLibrary'
import SimilarClauses from '@pages/SimilarClauses'
import FilterMenu from '@pages/FilterMenu'
import Definitions from '@pages/Definitions'
import DefinitionIssues from '@pages/DefinitionIssues'
import SectionNumbering from '@pages/SectionNumbering'
import SimilarClausesContextProvider, {
  SimilarClausesContext,
} from '@contexts/SimilarClausesContext'
import DocumentVersions from '@pages/DocumentVersions'
import KeyTerms from '@pages/KeyTerms'
import DefinitionsContextProvider from '@contexts/DefinitionsContext'
import KeyTermsContextProvider from '@contexts/KeyTermsContext'
import Authentication from '@components/Authentication'
import AnalysisContextProvider from '@contexts/AnalysisContext'
import StoreContextProvider from '@contexts/StoreContext'
import UnsupportedError from '@components/UnsupportedError'
import { clearDefinedTermsInDocument } from '@modules/DocumentDefinitions'
import { LightTheme } from '@src/themes'
import CreateContract from '@pages/CreateContract'
import Layout from '@pages/Layout'
import useTokenAutoRefresh from '@hooks/useTokenAutoRefresh'
import VersionsContextProvider from '@contexts/VersionsContext'
import { loadAnalytics } from '@modules/analytics'
import ContractContextProvider from '@contexts/ContractContext'
import ContractsContextProvider, { ContractsContext } from '@contexts/ContractsContext'
import Outline from '@pages/Outline'
import SectionIssuesContextProvider from '@contexts/SectionIssuesContext'
import AnalysisToolsContextProvider from '@contexts/AnalysisToolsContext'
import ClauseAdviser from '@pages/ClauseAdviser'
import ContractChat from '@pages/ContractChat'
import TemplatesLibrary from '@pages/Templates'
import TemplatesContextProvider, { TemplatesContext } from '@contexts/TemplatesContext'
import ClausesContextProvider, { ClausesContext } from '@contexts/ClausesContext'
import SaveVersionDialog from '@components/SaveVersionDialog'
import { handleDetailInLocalStorage, loadAndRemoveStoredPage } from '@modules/ContractDetail'
import HomepageLayout from '@pages/HomepageLayout'
import ClauseAdviserDisabledModal from '@components/ClauseAdviserDisabledModal'
import PlaybooksComplianceSummary from '@pages/PlaybooksComplianceSummary'
import Playbooks from '@pages/Playbooks'
import PlaybooksContextProvider from '@contexts/PlaybooksContext'
import AddObligations from '@pages/AddObligations'

loadAnalytics()

// Inject some global styles
mergeStyles({
  ':global(#container)': {
    height: 'fit-content',
    minHeight: '100%',
    background: LightTheme.palette.white,
  },
})

interface Props {
  isOfficeInitialized: boolean
}

const App: FC<Props> = ({ isOfficeInitialized }: Props) => {
  const tokenAutoRefresh = useTokenAutoRefresh()

  const navigate = useNavigate()
  const navigateRibbon = newCommand(event => {
    navigateTaskPane(event.source.id, navigate)
  })

  useEffect(() => {
    handleDetailInLocalStorage()
    const route = loadAndRemoveStoredPage()
    if (route) navigate(route)

    void clearDefinedTermsInDocument()
    tryGettingDataInjection()
    tokenAutoRefresh()

    Office.actions.associate('navigateRibbon', navigateRibbon)
    Office.actions.associate('showSaveCurrentVersionDialogCmd', showSaveCurrentVersionDialogCmd)
    Office.actions.associate('showClauseAdviser', showClauseAdviserCmd)
    Office.actions.associate('hideTaskpane', hideTaskpane)
    // NOTE: next line's eslint disabled because the suggested fix to add
    // tokenAuthRefresh to the deps array or remove it, causes an endless render loop
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const hideTaskpane = newCommand(() => {
    Office.addin.hide()
  })

  const [showSaveCurrentVersionModal, setShowSaveCurrentVersionModal] = useState(false)
  const [showClauseAdviserDisabledModal, setShowClauseAdviserDisabledModal] = useState(false)

  const showSaveCurrentVersionDialogCmd = newCommand(() => {
    Office.addin.showAsTaskpane()
    setShowSaveCurrentVersionModal(true)
  })

  const showClauseAdviserCmd = newCommand(() => {
    Office.addin.showAsTaskpane()
    setShowClauseAdviserDisabledModal(true)
  })

  if (!isOfficeInitialized) {
    Office.addin.showAsTaskpane()
    return <Progress title="Bloomberg Law" logo="assets/logo-filled.png" message="Loading..." />
  }
  if (!isSupported()) return <UnsupportedError />
  return (
    <div style={{ height: '100%' }}>
      <StoreContextProvider>
        {showSaveCurrentVersionModal && (
          <ContractContextProvider>
            <VersionsContextProvider>
              <Authentication required>
                <SaveVersionDialog
                  showSaveCurrentVersionModal={showSaveCurrentVersionModal}
                  setShowSaveCurrentVersionModal={setShowSaveCurrentVersionModal}
                />
              </Authentication>
            </VersionsContextProvider>
          </ContractContextProvider>
        )}
        {showClauseAdviserDisabledModal && (
          <ClauseAdviserDisabledModal
            showClauseAdviserDisabledModal={showClauseAdviserDisabledModal}
            setShowClauseAdviserDisabledModal={setShowClauseAdviserDisabledModal}
          />
        )}
        <Routes>
          <Route element={<Layout />}>
            <Route
              path="/clauses"
              element={
                <KeyTermsContextProvider>
                  <ContractContextProvider>
                    <ClausesContextProvider>
                      <Authentication required>
                        <ClauseLibrary />
                      </Authentication>
                    </ClausesContextProvider>
                  </ContractContextProvider>
                </KeyTermsContextProvider>
              }
            />
            <Route
              path="/clauses/filters"
              element={
                <KeyTermsContextProvider>
                  <ContractContextProvider>
                    <ClausesContextProvider>
                      <Authentication required>
                        <FilterMenu context={ClausesContext} />
                      </Authentication>
                    </ClausesContextProvider>
                  </ContractContextProvider>
                </KeyTermsContextProvider>
              }
            />

            <Route
              path="/templates"
              element={
                <KeyTermsContextProvider>
                  <TemplatesContextProvider>
                    <ContractsContextProvider>
                      <Authentication required>
                        <TemplatesLibrary />
                      </Authentication>
                    </ContractsContextProvider>
                  </TemplatesContextProvider>
                </KeyTermsContextProvider>
              }
            />
            <Route
              path="/templates/filters"
              element={
                <KeyTermsContextProvider>
                  <TemplatesContextProvider>
                    <Authentication required>
                      <FilterMenu context={TemplatesContext} />
                    </Authentication>
                  </TemplatesContextProvider>
                </KeyTermsContextProvider>
              }
            />
            <Route
              path="/guidance"
              element={
                <KeyTermsContextProvider>
                  <ContractContextProvider>
                    <Authentication required>
                      <PracticalGuidance />
                    </Authentication>
                  </ContractContextProvider>
                </KeyTermsContextProvider>
              }
            />

            <Route
              path="/clauses/similar"
              element={
                <AnalysisContextProvider>
                  <AnalysisToolsContextProvider>
                    <SectionIssuesContextProvider>
                      <DefinitionsContextProvider>
                        <SimilarClausesContextProvider>
                          <Authentication required>
                            <SimilarClauses />
                          </Authentication>
                        </SimilarClausesContextProvider>
                      </DefinitionsContextProvider>
                    </SectionIssuesContextProvider>
                  </AnalysisToolsContextProvider>
                </AnalysisContextProvider>
              }
            />
            <Route
              path="/clauses/similar/filters"
              element={
                <AnalysisContextProvider>
                  <AnalysisToolsContextProvider>
                    <SectionIssuesContextProvider>
                      <DefinitionsContextProvider>
                        <SimilarClausesContextProvider>
                          <Authentication required>
                            <FilterMenu context={SimilarClausesContext} />
                          </Authentication>
                        </SimilarClausesContextProvider>
                      </DefinitionsContextProvider>
                    </SectionIssuesContextProvider>
                  </AnalysisToolsContextProvider>
                </AnalysisContextProvider>
              }
            />

            <Route
              path="/definitions/terms"
              element={
                <AnalysisContextProvider>
                  <AnalysisToolsContextProvider>
                    <DefinitionsContextProvider>
                      <Authentication required>
                        <Definitions />
                      </Authentication>
                    </DefinitionsContextProvider>
                  </AnalysisToolsContextProvider>
                </AnalysisContextProvider>
              }
            />
            <Route
              path="/definitions/issues"
              element={
                <AnalysisContextProvider>
                  <AnalysisToolsContextProvider>
                    <DefinitionsContextProvider>
                      <Authentication required>
                        <DefinitionIssues />
                      </Authentication>
                    </DefinitionsContextProvider>
                  </AnalysisToolsContextProvider>
                </AnalysisContextProvider>
              }
            />
            <Route
              path="/sections/issues"
              element={
                <AnalysisContextProvider>
                  <AnalysisToolsContextProvider>
                    <SectionIssuesContextProvider>
                      <Authentication required>
                        <SectionNumbering />
                      </Authentication>
                    </SectionIssuesContextProvider>
                  </AnalysisToolsContextProvider>
                </AnalysisContextProvider>
              }
            />

            <Route
              path="/contracts"
              element={
                <KeyTermsContextProvider>
                  <ContractsContextProvider>
                    <ContractContextProvider>
                      <Authentication required>
                        <Contracts />
                      </Authentication>
                    </ContractContextProvider>
                  </ContractsContextProvider>
                </KeyTermsContextProvider>
              }
            />
            <Route
              path="/contracts/detect"
              element={
                <KeyTermsContextProvider>
                  <ContractContextProvider>
                    <Authentication required>
                      <ContractDetect />
                    </Authentication>
                  </ContractContextProvider>
                </KeyTermsContextProvider>
              }
            />
            <Route
              path="contracts/versions/new"
              element={
                <VersionsContextProvider>
                  <KeyTermsContextProvider>
                    <ContractsContextProvider>
                      <ContractContextProvider>
                        <Authentication required>
                          <SaveNewVersion />
                        </Authentication>
                      </ContractContextProvider>
                    </ContractsContextProvider>
                  </KeyTermsContextProvider>
                </VersionsContextProvider>
              }
            />
            <Route
              path="/contracts/:id/addObligations"
              element={
                <KeyTermsContextProvider>
                  <ContractContextProvider>
                    <Authentication required>
                      <AddObligations />
                    </Authentication>
                  </ContractContextProvider>
                </KeyTermsContextProvider>
              }
            />
            <Route
              path="/contracts/:id/keyTerms"
              element={
                <KeyTermsContextProvider>
                  <ContractContextProvider>
                    <Authentication required>
                      <KeyTerms />
                    </Authentication>
                  </ContractContextProvider>
                </KeyTermsContextProvider>
              }
            />
            <Route
              path="/contracts/:contractId/documents/:documentId/versions"
              element={
                <VersionsContextProvider>
                  <KeyTermsContextProvider>
                    <ContractContextProvider>
                      <Authentication required>
                        <DocumentVersions />
                      </Authentication>
                    </ContractContextProvider>
                  </KeyTermsContextProvider>
                </VersionsContextProvider>
              }
            />
            <Route
              path="/contracts/new"
              element={
                <KeyTermsContextProvider>
                  <ContractsContextProvider fetchingItemsDefault={true}>
                    <Authentication required>
                      <CreateContract />
                    </Authentication>
                  </ContractsContextProvider>
                </KeyTermsContextProvider>
              }
            />
            <Route
              path="/contracts/:id"
              element={
                <KeyTermsContextProvider>
                  <ContractsContextProvider>
                    <ContractContextProvider>
                      <Authentication required>
                        <ContractView />
                      </Authentication>
                    </ContractContextProvider>
                  </ContractsContextProvider>
                </KeyTermsContextProvider>
              }
            />
            <Route
              path="/contracts/filters"
              element={
                <KeyTermsContextProvider>
                  <ContractsContextProvider>
                    <Authentication required>
                      <FilterMenu context={ContractsContext} />
                    </Authentication>
                  </ContractsContextProvider>
                </KeyTermsContextProvider>
              }
            />
            <Route
              path="/contracts/chat"
              element={
                <Authentication required>
                  <KeyTermsContextProvider>
                    <ContractContextProvider>
                      <ContractChat />
                    </ContractContextProvider>
                  </KeyTermsContextProvider>
                </Authentication>
              }
            />
            <Route
              path="/playbooks"
              element={
                <Authentication required>
                  <KeyTermsContextProvider>
                    <PlaybooksContextProvider>
                      <Playbooks />
                    </PlaybooksContextProvider>
                  </KeyTermsContextProvider>
                </Authentication>
              }
            />
            <Route
              path="/playbooks/compliance/summary"
              element={
                <Authentication required>
                  <PlaybooksComplianceSummary />
                </Authentication>
              }
            />
            <Route
              path="/outline"
              element={
                <AnalysisToolsContextProvider>
                  <KeyTermsContextProvider>
                    <ContractContextProvider>
                      <Authentication required>
                        <Outline />
                      </Authentication>
                    </ContractContextProvider>
                  </KeyTermsContextProvider>
                </AnalysisToolsContextProvider>
              }
            />
          </Route>
          <Route element={<HomepageLayout />}>
            <Route
              path="/clauseAdviser"
              element={
                <Authentication required>
                  <ClauseAdviser />
                </Authentication>
              }
            />
            <Route
              path="*"
              element={
                <KeyTermsContextProvider>
                  <ContractContextProvider>
                    <Authentication>
                      <Home />
                    </Authentication>
                  </ContractContextProvider>
                </KeyTermsContextProvider>
              }
            />
          </Route>
        </Routes>
      </StoreContextProvider>
    </div>
  )

  function tryGettingDataInjection() {
    try {
      const { get } = Office?.context?.document?.settings ?? {}
      if (!get) return console.warn('No Office settings', Office?.context?.document?.settings)

      console.debug({
        resourceId: get('resourceId'),
        documentId: get('documentId'),
        versionId: get('versionId'),
        templateId: get('templateId'),
      })
    } catch (e) {
      console.warn(`Couldn't read document settings`)
      console.error(e)
    }
  }
}

function isSupported() {
  if (navigator.userAgent.indexOf('Trident') !== -1) return false // no legacy IE support

  // We need either of the following APIs. Word Dialogs will only load the DialogAPI
  // The taskpane pages will load both
  return (
    Office?.context?.requirements?.isSetSupported &&
    (Office.context.requirements.isSetSupported('WordApi', '1.2') ||
      Office.context.requirements.isSetSupported('DialogApi', '1.2'))
  )
}

function newCommand(callback: (event: Office.AddinCommands.Event) => any) {
  return (event: Office.AddinCommands.Event) => {
    callback(event)
    event.completed()
  }
}

function navigateTaskPane(destination: string, navigate: NavigateFunction) {
  console.debug('Navigating to ' + destination)
  Office.addin.showAsTaskpane()
  switch (destination) {
    case 'Taskpane.Home':
      navigate('/')
      break
    case 'Taskpane.Contracts':
      navigate('/contracts')
      break
    case 'Taskpane.Clauses':
      navigate('/clauses')
      break
    case 'Taskpane.Templates':
      navigate('/templates')
      break
    case 'SimilarClauses.Button':
      navigate('/clauses/similar')
      break
    case 'SectionNumbering.Button':
      navigate('/sections/issues')
      break
    case 'DefinedTerms.Button':
      navigate('/definitions/terms')
      break
    case 'DefinitionIssues.Button':
      navigate('/definitions/issues')
      break
    case 'Guidance.Button':
      navigate('/guidance')
      break
    case 'ContractInfo.Button':
      navigate('/contracts/detect')
      break
    case 'Outline.Button':
      navigate('/outline')
      break
    case 'NewVersion.Button':
      navigate('/contracts/versions/new')
      break
    case 'ClauseAdviser.Button':
      navigate('/clauseAdviser')
      break
    case 'ContractChat.Button':
      navigate('/contracts/chat')
      break
    default:
      console.error('DID NOT HAVE ROUTE FOR: ' + destination)
  }
}

export default App
