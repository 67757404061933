import { CSSProperties, MouseEventHandler, ReactElement, useContext } from 'react'
import { useLocation, useMatch, useParams } from 'react-router-dom'
import {
  Stack,
  ActionButton,
  IStackStyles,
  ContextualMenuItemType,
  TooltipHost,
  FontWeights,
  IContextualMenuItem,
  DefaultEffects,
} from '@fluentui/react'

import ActionsMenu from '@components/ActionsMenu'
import BloombergLogo from '@components/BloombergLogo'
import BackButton from './BackButton'
import { HeaderNotificationTarget } from '@components/HeaderNotification'
import { selectedLeftBorderStyle, unselectedLeftBorderStyle } from '@modules/sharedStyles'
import { StoreContext } from '@contexts/StoreContext'
import AIBadge from '@components/AIBadge'
import useNavItems from '@hooks/useNavItems'
import AIGenerated from '@components/AIGenerated'
import useWindowSize from '@hooks/useWindowSize'
import BetaBadge from '@components/BetaBadge'

interface Props {
  title?: string | ReactElement
  prevPath?: string | (() => void)
  showPrimaryButton?: boolean
  showCloseIcon?: boolean
  showAIBadge?: boolean
  AIBadgeHref?: string
  showAIGeneratedBadge?: boolean
  primaryButtonLabel?: string
  primaryButtonTooltip?: string
  onPrimaryButtonClick?: MouseEventHandler<any>
  styles?: CSSProperties
}

const stackStyles: IStackStyles = {
  root: {
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: DefaultEffects.elevation4,
    marginBottom: '0.5em',
  },
}

export default function TopNav({
  title,
  prevPath,
  primaryButtonLabel = 'Add',
  primaryButtonTooltip = '',
  showPrimaryButton = false,
  onPrimaryButtonClick = () => null,
  showCloseIcon = false,
  showAIBadge = false,
  AIBadgeHref = '',
  showAIGeneratedBadge = false,
  styles,
}: Props) {
  const { access, isAIBadgeEnabled } = useContext(StoreContext)
  const { id } = useParams()
  const isViewingContractInfoPage = !!(useMatch('/contracts/:id') && id)
  const isViewingClauseAdviserPage = !!useMatch('/clauseAdviser')
  const isViewingContractChatPage = !!useMatch('/contracts/chat')
  const isViewingPlaybooksPage = !!useMatch('/playbooks')
  const isViewingAddObligations = !!useMatch('/contracts/:id/addObligations')
  const { preNavItems, mainNavItems, actionNavItems, analysisNavItems } = useNavItems()
  const windowSize = useWindowSize()
  const location = useLocation()
  const template = location.state?.template

  return (
    <>
      <Stack horizontal styles={{ ...stackStyles, ...styles }}>
        <Stack.Item>{renderPrimaryButton(isViewingContractInfoPage)}</Stack.Item>
        <Stack.Item style={{ display: 'flex', alignItems: 'center' }}>
          <h1
            style={{
              fontSize: '1.3rem',
              fontWeight: FontWeights.semibold,
              margin: '0',
              paddingBottom: windowSize.width < 400 && template ? '0.2em' : '',
            }}
          >
            {title || <BloombergLogo style={{ marginTop: '8px' }} />}
          </h1>
          <BetaBadge show={isViewingContractChatPage} />
        </Stack.Item>
        <Stack.Item>
          {PrimaryButton(
            onPrimaryButtonClick,
            showPrimaryButton,
            primaryButtonLabel,
            primaryButtonTooltip,
          )}
          <AIBadge
            show={
              (isAIBadgeEnabled || isViewingContractChatPage || isViewingPlaybooksPage) &&
              showAIBadge
            }
            href={AIBadgeHref}
          />
          <AIGenerated
            show={
              (isViewingClauseAdviserPage ||
               
                isViewingContractChatPage ||
                isViewingPlaybooksPage ||
               
                isViewingAddObligations ||
                showAIGeneratedBadge) &&
              !showAIBadge
            }
          />
        </Stack.Item>
      </Stack>
      <HeaderNotificationTarget />
    </>
  )

  function renderPrimaryButton(isViewingContractInfoPage: boolean) {
    if (prevPath) return renderBackButton(prevPath, showCloseIcon)

    return renderActionsMenu(access.canViewRoute(location.pathname), isViewingContractInfoPage)
  }

  function renderActionsMenu(canViewHomePage: boolean, isViewingContractInfoPage: boolean) {
    const styledPreNavItems = styledNavItems(preNavItems)
    const styledMainNavItems = styledNavItems(mainNavItems)
    const styledActionNavItems = styledNavItems(actionNavItems, isViewingContractInfoPage)
    const styledAnalysisNavItems = styledNavItems(analysisNavItems)
    const navGroups = [
      {
        key: 'pre',
        itemType: ContextualMenuItemType.Section,
        sectionProps: {
          bottomDivider: true,
          items: styledPreNavItems,
        },
      },
      {
        key: 'main',
        itemType: ContextualMenuItemType.Section,
        sectionProps: {
          bottomDivider: true,
          items: styledMainNavItems,
        },
      },
      {
        key: 'analysis',
        itemType: ContextualMenuItemType.Section,
        sectionProps: {
          bottomDivider: true,
          items: styledAnalysisNavItems,
        },
      },
      {
        key: 'action',
        itemType: ContextualMenuItemType.Section,
        sectionProps: {
          bottomDivider: true,
          items: styledActionNavItems,
        },
      },
    ]

    return (
      <ActionsMenu
        items={navGroups}
        menuIconProps={{
          iconName: 'GlobalNavButton',
        }}
        styles={{
          visibility: canViewHomePage ? 'visible' : 'hidden',
        }}
      />
    )
  }
}

function PrimaryButton(
  onClick: MouseEventHandler<any>,
  show: boolean,
  label: string,
  tooltip: string,
) {
  return (
    <TooltipHost content={tooltip}>
      <ActionButton
        text={label}
        iconProps={{ iconName: 'Add' }}
        onClick={onClick}
        style={{ display: show ? 'inline-block' : 'none', translate: '-0.5em 0' }}
      />
    </TooltipHost>
  )
}

function styledNavItems(items: IContextualMenuItem[], isViewingContractInfoPage = false) {
  return items.map(item => {
    if (
      item.href === window.location.hash ||
      (isViewingContractInfoPage && item.key === 'Contract Info')
    ) {
      item.style = selectedLeftBorderStyle
      return item
    } else {
      item.style = unselectedLeftBorderStyle
      return item
    }
  })
}

function renderBackButton(prevPath: string | (() => void), renderBackIcon: boolean) {
  if (typeof prevPath === 'string')
    return <BackButton renderBackIcon={renderBackIcon} href={prevPath} />

  return <BackButton renderBackIcon={renderBackIcon} onClick={prevPath} />
}
