import StyledTextField from '@components/StyledTextField'
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react'
import { OXFormProps } from '@modules/OXForm'

const options: IChoiceGroupOption[] = [
  { key: 'Yes', text: 'Yes' },
  { key: 'No', text: 'No' },
]

export default function BooleanOXForm({ label }: OXFormProps) {
  return (
    <>
      <ChoiceGroup
        // styles={{
        //   flexContainer: { display: 'flex', justifyContent: 'space-between', width: '45%' },
        // }}
        label={label}
        options={options}
        required
        // onChange={updateBooleanKeyTerm}
        // selectedKey={
        //   typeof newKeyTerm?.value === 'boolean'
        //     ? booleanToOptionMap[newKeyTerm.value ? 1 : 0]
        //     : null
        // }
      />
      <StyledTextField
        label="Notes"
        // value={newKeyTerm?.data ? newKeyTerm?.data.notes : ''}
        name="notes"
        // onChange={updateKeyTermNotes}
        multiline
      />
      {/* <CopyFromSelectionBtn onClick={copyFromSelection} disabled={copySelectionDisabled} /> */}
    </>
  )
}
