import { PropsWithChildren, ReactNode } from 'react'
import {
  ActivityItem,
  FontSizes,
  IActivityItemStyles,
  Icon,
  SharedColors,
  Text,
} from '@fluentui/react'
import { t } from 'i18next'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import BoldText from '@baseComponents/BoldText'
import LastSeen from '@components/LastSeen'

export type ChatMessageProps = PropsWithChildren & {
  from?: string
  activity?: string | ReactNode | ReactNode[]
  timeStamp?: Date
  markdown?: string
  isCompact?: boolean
  iconName?: string
  iconSize?: string
  styles?: IActivityItemStyles
}

export const chatbotName = t('page.Chat with Contract.Chatbot Name')

export default function ChatMessage({
  styles,
  children,
  isCompact,
  timeStamp,
  markdown,
  from = chatbotName,
  activity = 'commented',
  iconName = 'ChatBot',
  iconSize = FontSizes.xxLarge,
}: ChatMessageProps) {
  const date = typeof timeStamp === 'string' ? new Date(Date.parse(timeStamp)) : timeStamp
  return (
    <ActivityItem
      activityIcon={
        <Icon iconName={iconName} style={{ fontSize: iconSize, color: SharedColors.cyanBlue10 }} />
      }
      activityDescription={
        <>
          <BoldText>{from} </BoldText>
          <Text variant="small">{activity}</Text>
        </>
      }
      comments={renderComments()}
      timeStamp={date && <LastSeen date={date} />}
      isCompact={isCompact}
      style={{ marginBottom: '1em' }}
      styles={styles}
    />
  )

  function renderComments() {
    if (markdown)
      return (
        <Text aria-label="chat message">
          <ReactMarkdown rehypePlugins={[rehypeRaw]} components={{ p: 'span' }}>
            {markdown}
          </ReactMarkdown>
        </Text>
      )

    return <Text aria-label="chat message">{children}</Text>
  }
}
