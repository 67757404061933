import { useContext, useEffect } from 'react'
import { Stack } from '@fluentui/react'
import { useTranslation } from 'react-i18next'

import { useContractTaskPaneViewed } from '@modules/analytics'
import TopNav from '@components/TopNav'
import StyledStack from '@components/StyledStack'
import { PlaybooksContext } from '@contexts/PlaybooksContext'
import QuickMessage from '@components/QuickMessage'
import PlaybooksList from '@components/PlaybooksList'
import { StoreContext } from '@contexts/StoreContext'
import useWindowSize from '@hooks/useWindowSize'

const pageTitle = 'My Playbooks'

export default function Playbooks() {
  const { query, error, setError, warn, Pagination, topOfPageRef, loading, playbooks, loadPage } =
    useContext(PlaybooksContext)
  const { access } = useContext(StoreContext)
  const { t } = useTranslation()
  const label = t('label.playbook')
  const windowSize = useWindowSize()

  useEffect(() => {
    setError('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playbooks.length])

  useEffect(() => {
    if (location.pathname.startsWith('/playbooks')) {
      loadPage(query)
    }
  })

  useContractTaskPaneViewed({ pageTitle })

  return (
    <div ref={topOfPageRef}>
      <TopNav
        title={pageTitle}
        showPrimaryButton={access.canCreatePlaybook()}
        primaryButtonLabel="Create"
        primaryButtonTooltip="Create Playbook"
        onPrimaryButtonClick={() => null}
        showAIBadge={windowSize.width < 400}
        AIBadgeHref={'https://www.bloombergindustry.com/ai-notice/'}
      />

      <StyledStack>
        <Stack.Item>{renderPlaybooks()}</Stack.Item>
      </StyledStack>
    </div>
  )

  function renderPlaybooks() {
    if (error) return <QuickMessage msg={error} type="error" />
    if (warn) return <QuickMessage msg={warn} type="warning" />
    if (!playbooks.length && !loading)
      return <QuickMessage msg={`0 ${label} found`} type="warning" />

    return (
      <Pagination>
        <PlaybooksList resources={playbooks} loading={loading} error={error} />
      </Pagination>
    )
  }
}
