import { useState } from 'react'

import Routes from '@modules/routes'
import ApiClient from '@modules/ApiClient'
import { SessionInfo } from '@modules/SessionInfoStorage'
import { FeatureFlag, featureFlagsMap } from '@modules/FeatureFlag'
import { ADD_IN_HOST } from '@src/constants'

const flagStateKeys = {
  setNavigateToExtractedObligationEnabled: 'KMGT:ENABLE_NAVIGATE_TO_EXTRACTED_OBLIGATION',
  setIsResourceAccessEnabled: 'KMGT:ENABLE_RESOURCE_ACCESS',
  setIsClauseAdviserEnabled: 'KMGT:ENABLE_CLAUSE_ADVISER',
  setIsAIBadgeEnabled: 'KMGT:SHOW_AI_BADGES',
  setIsContractChatEnabled: 'KMGT:ENABLE_CONTRACT_CHAT',
  setIsPlaybooksEnabled: 'KMGT:ENABLE_PLAYBOOKS_COMPLIANCE',
  setIsOnDemandOxEnabled: 'KMGT:ENABLE_ON_DEMAND_OX',
} as const

type FlagKeys = keyof typeof flagStateKeys
type FlagName = (typeof flagStateKeys)[FlagKeys]
type Props = {
  storeSessionInfo: (info: SessionInfo | null) => void
}

// Feature Flags are created in https://blaw-re-settings.stacker.dev.bloomberg.com/settings
// After creating a new flag, add the state setter and flag name to `flagStateKeys`.
// Then create a useState and return value like the other feature flags below.
// see `useNavItems` if a page needs to be feature flagged and disabled in the nav.
export default function useFeatureFlags({ storeSessionInfo }: Props) {
  const routes = new Routes()
  const apiClient = new ApiClient(storeSessionInfo)

  const [isResourceAccessEnabled, setIsResourceAccessEnabled] = useState(false)
  const [isClauseAdviserEnabled, setIsClauseAdviserEnabled] = useState(false)
  const [isContractChatEnabled, setIsContractChatEnabled] = useState(false)
  const [isAIBadgeEnabled, setIsAIBadgeEnabled] = useState(false)
  const [isPlaybooksEnabled, setIsPlaybooksEnabled] = useState(false)
  const [navigateToExtractedObligationEnabled, setNavigateToExtractedObligationEnabled] =
    useState(false)
  const [isOnDemandOxEnabled, setIsOnDemandOxEnabled] = useState(false)

  const setters = {
    setIsAIBadgeEnabled,
    setIsClauseAdviserEnabled,
    setIsContractChatEnabled,
    setIsPlaybooksEnabled,
    setIsResourceAccessEnabled,
    setNavigateToExtractedObligationEnabled,
    setIsOnDemandOxEnabled,
  }

  return {
    isAIBadgeEnabled,
    isClauseAdviserEnabled,
    isContractChatEnabled,
    isResourceAccessEnabled,
    loadFlagDataAndSetState,
    navigateToExtractedObligationEnabled,
    isPlaybooksEnabled,
    isOnDemandOxEnabled,
  }

  async function loadFlagDataAndSetState() {
    const flags = featureFlagsMap(await getFeatureFlags())
    const flagState = Object.entries(flagStateKeys).reduce(
      (data, [key, name]) => ({
        ...data,
        [name]: { key, value: flags[name] === 'true' },
        // comment out the above and uncomment below to enable all featureFlags in local dev
        // [name]: { key, value: flags[name] === 'true' || ADD_IN_HOST.includes('local') },
      }),
      {} as { [Property in FlagName]: { key: FlagKeys; value: boolean } },
    )
    Object.entries(flagState).forEach(([_, { key, value }]) => setters[key](value))

    return flagState
  }

  async function getFeatureFlags() {
    try {
      const url = routes.featureFlagsUrl('allFlags')
      const { data } = await apiClient.get<FeatureFlag[]>(url)
      return data
    } catch (e) {
      console.error(e)
      return []
    }
  }
}
