import { type PropsWithChildren, useContext, useEffect, useRef, useState } from 'react'
import { renderToString } from 'react-dom/server'
import {
  DefaultButton,
  DefaultEffects,
  FocusTrapZone,
  FontSizes,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  Text,
  TextField,
  type IActivityItemStyles,
} from '@fluentui/react'
import { useId, useSetTimeout } from '@fluentui/react-hooks'

import ApiClient from '@modules/ApiClient'
import BoldText from '@baseComponents/BoldText'
import ChatMessage from '@components/ChatMessage'
import ErrorMessage from '@components/ErrorMessage'
import LinkButton from '@components/LinkButton'
import Routes from '@modules/routes'
import StyledStack from '@components/StyledStack'
import TopNav from '@components/TopNav'
import UnstyledList from '@baseComponents/UnstyledList'
import useLocalState from '@hooks/useLocalState'
import { chatbotName, type ChatMessageProps } from '@components/ChatMessage/ChatMessage'
import { ContractContext } from '@contexts/ContractContext'
import { getDocBodyText } from '@modules/wordDocument'
import { LightTheme } from '@src/themes'
import { type LLMRequest, type LLMResponse, type Message } from '@blaw/contracts-api-schema'
import { StoreContext } from '@contexts/StoreContext'
import { formSubmitted, useContractTaskPaneViewed } from '@modules/analytics'
import { FormSubmitEvent } from '@modules/utils'
import SmallButton from '@components/SmallButton'
import ConfirmDialog from '@components/ConfirmDialog'
import useWindowSize from '@hooks/useWindowSize'

const pageTitle = 'Contract Assistant'
const apiClient = new ApiClient()
const routes = new Routes()
const userMessageStyles: IActivityItemStyles = {
  root: {
    padding: 0,
    alignSelf: 'end',
    marginLeft: '0.7em',
    textAlign: 'right',
    borderRadius: '0.1em',
    flexDirection: 'row-reverse',
  },
  commentText: {
    backgroundColor: LightTheme.palette.themeLighterAlt,
    boxShadow: DefaultEffects.elevation16,
    textAlign: 'left',
    padding: '0.3em 0.5em',
    margin: '0.3em 0',
  },
}

export default function ContractChat() {
  const { contractId, contract, loading } = useContext(ContractContext)
  const { isContractChatEnabled } = useContext(StoreContext)
  const [currentMessage, setCurrentMessage] = useState('')
  const [sending, setSending] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [hideNewChatConfirm, setHideNewChatConfirm] = useState<boolean>(true)
  const { setTimeout } = useSetTimeout()
  const inputRef = useRef<HTMLInputElement>(null)
  const topRef = useRef<HTMLDivElement>(null)
  const bottomRef = useRef<HTMLDivElement>(null)
  const chatId = useId('chat')
  const [isLongHistory, setIsLongHistory] = useState(hasScroll(chatId))

  const [title, setLocalTitle] = useLocalState<string>('', `title-${contractId}`, 'contract-title')
  const [chatMessages, setLocalChatMessages, clear] = useLocalState<ChatMessageProps[]>(
    [],
    contractId,
    `contract-chat`,
  )
  const windowSize = useWindowSize()

  const focusInput = () => inputRef.current?.focus()
  const scrollToBottom = (behavior: ScrollBehavior = 'smooth') => {
    bottomRef.current?.scrollIntoView({ behavior })
  }
  const isCurrentMessageEmpty = !currentMessage.replaceAll(/\n/g, '').trim().length
  const disabled = submitting || !isContractChatEnabled || isCurrentMessageEmpty
  const clearChatDisabled = submitting || chatMessages.length === 0
  const isExistingChat = chatMessages.length > 0
  const showDetailedIntro = title || !(loading && isExistingChat)
  const showProgress = sending || (loading && !title && !isExistingChat)

  useContractTaskPaneViewed({ pageTitle })

  useEffect(() => {
    if (!title && contract?.title) setLocalTitle(contract.title)
  }, [contract?.title])

  useEffect(() => {
    setIsLongHistory(hasScroll(chatId))
    scrollToBottom('instant' as ScrollBehavior)
  }, [chatMessages.length])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 3.3em)' }}>
      <TopNav
        title={pageTitle}
        showAIBadge={windowSize.width < 400}
        AIBadgeHref={'https://www.bloombergindustry.com/ai-notice/'}
      />

      <form
        onSubmit={e => !submitting && submit(currentMessage, e)}
        style={{ display: 'flex', flexGrow: 1, position: 'relative', minHeight: 0 }}
      >
        <StyledStack
          className="chat"
          style={{ padding: '0 0 0.5em 0.7em', display: 'flex', flexGrow: '1' }}
        >
          <Stack.Item
            id={chatId}
            style={{
              display: 'flex',
              flexGrow: '1',
              paddingRight: '0.5em',
              flexDirection: 'column',
              overflowY: 'auto',
            }}
          >
            <div ref={topRef}></div>

            <ChatMessage styles={{ root: { marginTop: '1em' } }}>
              <Text>Responses from Bloomberg Law&apos;s AI Assistant are </Text>
              <BoldText>AI Generated. </BoldText>
              <Text>
                The AI Assistant is experimental in nature, and users are strongly advised to
                corroborate the information obtained, exercise professional judgement, and seek out
                additional sources of information as deemed necessary.
              </Text>
            </ChatMessage>

            {showDetailedIntro && (
              <ChatMessage>
                {title && <ContractDetail />}
                <Text>
                  You can ask questions related to the content of this document. Here are some
                  examples:
                </Text>
                <UnstyledList style={{ marginTop: '0.3em' }}>
                  <SampleQuestionButton>Can this contract be renewed?</SampleQuestionButton>
                  <SampleQuestionButton>
                    What jurisdiction governs the construction of this contract?
                  </SampleQuestionButton>
                  <SampleQuestionButton>
                    How long will this contract remain in effect?
                  </SampleQuestionButton>
                </UnstyledList>
              </ChatMessage>
            )}

            {chatMessages.map((item, i) => (
              <ChatMessage
                key={i}
                from={item.from}
                activity={item.activity}
                timeStamp={item.timeStamp}
                markdown={item.markdown}
                iconName={item.iconName}
                iconSize={item.iconSize}
                styles={item.from === 'You' ? userMessageStyles : {}}
              >
                {item.children}
              </ChatMessage>
            ))}

            {showProgress && <ChatMessage activity={<ProgressIndicator />} isCompact={true} />}
            <div ref={bottomRef}></div>
          </Stack.Item>

          <FocusTrapZone
            disabled={disabled}
            forceFocusInsideTrap={false}
            isClickableOutsideFocusTrap
          >
            <div style={{ margin: '0.3em 0.7em 0 0' }}>
              <ErrorMessage message={error} />
            </div>
            <Stack.Item style={{ display: 'flex', margin: '0 0.7em 0.5em 0' }}>
              <Stack horizontal style={{ flexGrow: '1', gap: '0.5em' }}>
                <TextField
                  // @ts-ignore ref works fine
                  ref={inputRef}
                  autoFocus
                  multiline
                  autoAdjustHeight
                  value={currentMessage}
                  placeholder="Ask a question..."
                  onKeyDown={event => {
                    if (event.key === 'Enter' && !event.shiftKey && !isCurrentMessageEmpty) {
                      submit(currentMessage, event)
                    } else if (event.key === 'Enter' && isCurrentMessageEmpty) {
                      event.preventDefault()
                    }
                  }}
                  onChange={(_, value) => setCurrentMessage(value ?? '')}
                  styles={{ root: { flexGrow: 1 } }}
                />
                <PrimaryButton
                  disabled={disabled}
                  onClick={e => submit(currentMessage, e)}
                  iconProps={{ iconName: 'Send' }}
                  styles={{ root: { flexGrow: 0.1, padding: 0, minWidth: 0, height: 'auto' } }}
                />
              </Stack>
            </Stack.Item>

            <Stack.Item>
              <Stack horizontal style={{ justifyContent: 'space-between' }}>
                <SmallButton
                  iconName="Chat"
                  variant="primary"
                  disabled={clearChatDisabled}
                  onClick={() => setHideNewChatConfirm(false)}
                >
                  Start New Chat
                </SmallButton>
                <SmallButton
                  iconName="ChevronUpEnd6"
                  disabled={!isLongHistory}
                  onClick={() => topRef.current?.scrollIntoView({ behavior: 'smooth' })}
                  style={{ alignSelf: 'flex-end' }}
                >
                  Go to top
                </SmallButton>
              </Stack>
            </Stack.Item>
          </FocusTrapZone>
        </StyledStack>
      </form>

      <ConfirmDialog
        title="Start New Chat"
        hidden={hideNewChatConfirm}
        confirm="Start New Chat"
        onConfirm={() => {
          formSubmitted({
            pageTitle,
            itemClicked: 'Start New Chat',
          })
          setHideNewChatConfirm(true)
          clear()
        }}
        onDismiss={() => setHideNewChatConfirm(true)}
        onDismissed={focusInput}
      >
        Starting a new chat will erase the currently opened chat. Please confirm you want to start a
        new chat session.
      </ConfirmDialog>
    </div>
  )

  function ContractDetail() {
    return (
      <>
        <Text>This looks like a document from the </Text>
        <LinkButton href={`#/contracts/${contractId}`} buttonStyles={{ root: { lineHeight: 1 } }}>
          &quot;{title}&quot;&nbsp;
        </LinkButton>
        <Text>contract. </Text>
      </>
    )
  }

  function SampleQuestionButton({ children }: PropsWithChildren) {
    return (
      <li>
        <DefaultButton
          disabled={sending}
          onClick={e => submit(`${children}`, e, true)}
          styles={{
            root: { height: 'auto', width: '100%', margin: '0.3em 0' },
            label: { padding: '0.5em 0' },
          }}
        >
          {children}
        </DefaultButton>
      </li>
    )
  }

  async function submit(messageText: string, e: FormSubmitEvent, isSampleQuestion = false) {
    formSubmitted({
      pageTitle,
      itemClicked: 'Send Message',
      eventDetails: [`isSampleQuestion=${isSampleQuestion}`],
    })

    const chat = [
      ...chatMessages,
      {
        from: 'You',
        iconName: 'Message',
        timeStamp: new Date(),
        iconSize: FontSizes.xLarge,
        children: messageText,
      },
    ]
    setError(null)
    setSubmitting(true)
    setCurrentMessage('')
    setLocalChatMessages(chat)
    setTimeout(() => setSending(true), 300)
    setTimeout(() => bottomRef.current?.scrollIntoView({ behavior: 'smooth' }), 310)
    focusInput()
    e.preventDefault()

    try {
      const req: LLMRequest = {
        text: await getDocBodyText(),
        messages: serialize(chat),
      }
      const { data } = await apiClient.post<LLMResponse>(routes.contractChatUrl, req)
      const reply: ChatMessageProps = {
        from: chatbotName,
        markdown: data.response.value,
        timeStamp: new Date(),
      }
      setLocalChatMessages([...chat, reply])
    } catch (e) {
      console.error(e)
      setError((e as Error).message)
      setLocalChatMessages(chatMessages.slice(1))
      setCurrentMessage(messageText)
    } finally {
      setSending(false)
      setSubmitting(false)
      setTimeout(() => scrollToBottom(), 0)
    }
  }
}

function hasScroll(id: string) {
  const el = document.getElementById(id)
  return Boolean(el && el.scrollHeight > el.clientHeight)
}

function serialize(messages: ChatMessageProps[]) {
  return messages.reduce((history, { from, children, markdown }) => {
    return [
      ...history,
      {
        role: from === chatbotName ? 'assistant' : 'user',
        content: renderToString(children || markdown),
      },
    ]
  }, [] as Message[])
}
