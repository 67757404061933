import StyledTextField from '@components/StyledTextField'
import { OXFormProps } from '@modules/OXForm'

export default function DurationMonthsOXForm({ label }: OXFormProps) {
  return (
    <>
      <StyledTextField
        label={label}
        name="number"
        suffix="months"
        // onChange={updateNumericKeyTerm}
        required
        type="number"
        min="0"
        // value={removeNonNumberValues(newKeyTerm)}
        // errorMessage={validationError}
      />
      <StyledTextField
        label="Notes"
        multiline
        name="notes"
        // onChange={updateKeyTermNotes}
        // value={newKeyTerm?.data ? newKeyTerm?.data.notes : ''}
      />
      {/* <CopyFromSelectionBtn onClick={copyFromSelection} disabled={copySelectionDisabled} /> */}
    </>
  )
}
