import StyledTextField from '@components/StyledTextField'
import { ComboBox } from '@fluentui/react'
import { useTranslation } from '@hooks/useTranslation'
import { OXFormProps } from '@modules/OXForm'

export default function ChoiceTextOXForm({ label }: OXFormProps) {
  const { t } = useTranslation()
  return (
    <>
      <ComboBox
        required
        label={label}
        // styles={comboBoxStyles}
        placeholder={t('placeholder.select-one')}
        // defaultSelectedKey={newKeyTerm?.value?.toString()}
        options={[]}
        // onChange={updateChoiceTextKeyTerm}
        allowFreeInput
      />
      {/* <CopyFromSelectionBtn onClick={copyFromSelection} disabled={copySelectionDisabled} /> */}
      <StyledTextField
        label="Notes"
        // value={newKeyTerm?.data?.notes.toString()}
        name="notes"
        // onChange={updateKeyTermNotes}
        // styles={{ root: { marginTop: '1em' } }}
        multiline
      />
    </>
  )
}
