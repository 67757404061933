import { useContext, useEffect, useState, FC } from 'react'
import { Label, MessageBar, PrimaryButton, Toggle } from '@fluentui/react'
import { useNavigate } from 'react-router-dom'

import {
  type MetadataConfigValue,
  type TermsMetadataKey,
  type ResourceVersionMetadataDetails,
  MetadataTypes,
} from '@blaw/contracts-api-schema'

import { useTranslation } from '@hooks/useTranslation'
import { useDataInjection } from '@hooks/useDataInjection'

import { ContractContext } from '@contexts/ContractContext'
import { StoreContext } from '@contexts/StoreContext'
import { KeyTermsContext } from '@contexts/KeyTermsContext'

import { OXFormProps } from '@modules/OXForm'
import ApiClient from '@modules/ApiClient'
import Routes from '@modules/routes'
import { useContractTaskPaneViewed } from '@modules/analytics'

import Box from '@baseComponents/Box'
import UnstyledList from '@baseComponents/UnstyledList'
import BoldText from '@baseComponents/BoldText'
import ContractTitle from '@baseComponents/ContractTitle'

import CollapsibleItem from '@components/CollapsibleItem'
import TopNav from '@components/TopNav'
import StyledStack from '@components/StyledStack'
import LoadingShimmer from '@components/LoadingShimmer'
import ErrorMessage from '@components/ErrorMessage'
import DateOXForm from '@components/DateOXForm'
import TextOXForm from '@components/TextOXForm'
import BooleanOXForm from '@components/BooleanOXForm'
import CurrencyOXForm from '@components/CurrencyOXForm'
import DurationMonthsOXForm from '@components/DurationMonthsOXForm'
import DurationDaysOXForm from '@components/DurationDaysOXForm'
import ChoiceTextOXForm from '@components/ChoiceTextOXForm'

import {
  mockExtractedObligations,
  mockFormattedExtractedObligations,
} from '@fixtures/extractedObligations'

type KeyTermDetailTemp = {
  system_extracted: boolean
}

// TODO: type annotate key
// https://jira.bna.com/browse/KMGT-6660
export type GetObligationsResponse = {
  obligations: {
    [key: string]: ExtractedObligation[]
  }
}

// TODO: share types between contracts-api and add-in
// https://jira.bna.com/browse/KMGT-6659
type ExtractedObligation = {
  code: string
  data_type: string
  is_deleted: boolean
  type: string
  value: string
  status: string
  reference: string
  offsets: string[][]
  schema_key: string
  schema_type: MetadataTypes
  schema_label: string
}

interface FormattedExtractedObligation extends ExtractedObligation {
  active: boolean
  key: string
}

const OXTypeToFormComponent: {
  [key: string]: FC<OXFormProps>
} = {
  BOOLEAN: BooleanOXForm,
  CURRENCY: CurrencyOXForm,
  DATE: DateOXForm,
  DURATION_MONTHS: DurationMonthsOXForm,
  DURATION_DAYS: DurationDaysOXForm,
  TEXT: TextOXForm,
  CHOICE_TEXT: ChoiceTextOXForm,
}

const pageTitle = 'Add with GenAI'
const apiClient = new ApiClient()
const routes = new Routes()

export default function AddObligations() {
  const [error, setError] = useState('')
  const [loadingObligations, setLoadingObligations] = useState(true)
  const [obligations, setObligations] = useState([] as FormattedExtractedObligation[])
  const { isOnDemandOxEnabled } = useContext(StoreContext)
  const { contract, title, loading } = useContext(ContractContext)
  const { metadataConfig, loadingMetadataConfig } = useContext(KeyTermsContext)

  const navigate = useNavigate()
  const { t } = useTranslation()
  const { documentId, versionId } = useDataInjection()

  !loading && console.log(contract?.id, documentId, versionId)

  async function getObligations() {
    // if (!documentId || !versionId) return { obligations: {} }
    // const { data } = await apiClient.get<GetObligationsResponse>(
    //   routes.getObligationsUrl(documentId, versionId),
    // )
    // return data
    return mockExtractedObligations
  }

  function formatAndFilterObligations(
    obligations: ExtractedObligation[],
    metadataConfigValue: MetadataConfigValue[],
    keyTermsDetails: ResourceVersionMetadataDetails,
  ) {
    console.log('KEYTERMSDETAILS', keyTermsDetails)
    const formattedObligations = obligations.map((obligation, index) => {
      return {
        ...obligation,
        key: index.toString(),
        active: false,
      }
    })
    const formattedAndFilteredObligations = formattedObligations.filter(obligation => {
      const keyTermConfig =
        metadataConfigValue.find(term => term.id === obligation.schema_key) ||
        ({} as MetadataConfigValue)
      const keyTermDetail =
        keyTermsDetails[obligation.schema_key as TermsMetadataKey] || ({} as KeyTermDetailTemp)
      return displayObligation(keyTermConfig, keyTermDetail, obligation)
    })
    return formattedAndFilteredObligations
  }

  function displayObligation(
    keyTermConfig: MetadataConfigValue,
    keyTermDetail: KeyTermDetailTemp,
    obligation: FormattedExtractedObligation,
  ) {
    console.log(obligation.schema_key, keyTermConfig, keyTermDetail)
    // obligation is not editable from key terms page
    if (!Object.keys(keyTermConfig).length) {
      console.log(`${obligation.schema_key} is not editable`)
      return false
    }
    // obligation has already been manually entered on key terms page
    if (Object.keys(keyTermDetail).length && !keyTermDetail.system_extracted) {
      console.log(`${obligation.schema_key}  was already manually entered`)
      return false
    }
    // obligation is of the wrong type and doesn't have a system extracted value
    if (obligation.schema_type !== 'TEXT' && !keyTermDetail.system_extracted) {
      console.log(`${obligation.schema_key} is of the wrong type`)
      return false
    }
    return true
  }

  useEffect(() => {
    if (
      !contract ||
      loadingMetadataConfig ||
      !metadataConfig ||
      !metadataConfig?.value.length ||
      !Object.keys(contract.details).length
    )
      return

    setLoadingObligations(true)
    getObligations()
      .then(response => {
        const obligations = Object.entries(response.obligations).map(obligation => obligation[1][0])
        const formattedAndFilteredObligations = formatAndFilterObligations(
          obligations,
          metadataConfig.value,
          contract.details,
        )
        setObligations(formattedAndFilteredObligations)
      })
      .catch(error => {
        setError(error.message)
      })
      .finally(() => {
        setLoadingObligations(false)
      })
  }, [contract, loadingMetadataConfig, metadataConfig])

  // use the following for any access related checks
  // const { access } = useContext(StoreContext)

  useContractTaskPaneViewed({ pageTitle })

  if (error) return <ErrorMessage message={error} />
  if (!isOnDemandOxEnabled)
    return <ErrorMessage message={t('page.Extract Obligations.Service Unavailable')} />

  return (
    <>
      <TopNav title={pageTitle} prevPath={() => navigate(-1)} />
      <MessageBar>{t('page.Extract Obligations.Select Extracted Terms')}</MessageBar>
      <StyledStack>{renderMain()}</StyledStack>
    </>
  )

  function renderMain() {
    if (loading || loadingMetadataConfig || loadingObligations) return <LoadingShimmer />
    if (!obligations.length)
      return <MessageBar>{t('page.Extract Obligations.No Extracted Obligations')}</MessageBar>
    return (
      <>
        <ContractTitle title={title} />
        <UnstyledList>{obligations.map(renderExtractedObligation)}</UnstyledList>
        <PrimaryButton
          type="submit"
          // disabled={extracting || extractingRef.current || !isValid}
          // onClick={onSubmit}
          style={{ width: '100%' }}
        >
          {t('page.Extract Obligations.Add Selected')}
        </PrimaryButton>
      </>
    )
  }

  function renderExtractedObligation(obligation: FormattedExtractedObligation) {
    const FormComponent = OXTypeToFormComponent[obligation.schema_type]
    return (
      <li key={obligation.key}>
        <Box>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5em' }}>
            <Toggle styles={{ root: { marginRight: '0.5em', marginBottom: '0' } }} defaultChecked />
            <Label>{obligation.schema_label}</Label>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <BoldText>Suggestion: </BoldText>
            <div style={{ margin: '0.5em 0' }}>{obligation.value}</div>
          </div>
          <CollapsibleItem
            item={obligation}
            itemHeader={() => <div>Contract Text Snippet</div>}
            itemContent={() => <div>{obligation.reference}</div>}
            iconStyles={{ paddingLeft: 0 }}
            listItemStyles={{ border: 'none' }}
          />
          <FormComponent label={obligation.schema_label} />
        </Box>
      </li>
    )
  }
}
