import { useContext, useState, useMemo } from 'react'
import {
  Dropdown,
  Label,
  mergeStyles,
  MessageBar,
  MessageBarType,
  Pivot,
  PivotItem,
  ResponsiveMode,
  Stack,
  TextField,
} from '@fluentui/react'

import { StyledDivider } from '@baseComponents/StyledDivider'
import AddNewClauseForm from '@components/AddNewClauseForm'
import Clause from '@components/Clause'
import ClauseContent from '@components/ClauseContent'
import LoadingShimmer from '@components/LoadingShimmer'
import StyledStack from '@components/StyledStack'
import TopNav from '@components/TopNav'
import { StoreContext } from '@contexts/StoreContext'
import { useTranslation } from '@hooks/useTranslation'

import { useContractTaskPaneViewed } from '@modules/analytics'
import useWindowSize from '@hooks/useWindowSize'
import {
  AllClausesLabel,
  ClausesContext,
  CompanyClausesLabel,
  MyClausesLabel,
  TabIndex,
} from '@contexts/ClausesContext'
import FilterButton from '@components/FilterButton'
import KeywordSearch from '@components/KeywordSearch'

mergeStyles({
  ':global(.clauseDetails)': {
    marginBottom: '0.3em',
  },
  ':global(.clauseDetails dt)': {
    fontWeight: 600,
    float: 'left',
    marginRight: '0.5em',
  },
})

const pageTitle = 'Clauses'
const TabNavIndex: Record<string, TabIndex> = {
  [AllClausesLabel]: '0',
  [CompanyClausesLabel]: '1',
  [MyClausesLabel]: '2',
}
export const AllClausesTabIndex = TabNavIndex[AllClausesLabel]
export const CompanyClausesTabIndex = TabNavIndex[CompanyClausesLabel]
export const MyClausesTabIndex = TabNavIndex[MyClausesLabel]
export const TabInfo = {
  [AllClausesTabIndex]: {
    label: AllClausesLabel,
    helpText: "All clauses from my company's finalized contracts.",
  },
  [CompanyClausesTabIndex]: {
    label: CompanyClausesLabel,
    helpText: 'My company clauses.',
  },
  [MyClausesTabIndex]: {
    label: MyClausesLabel,
    helpText: 'My favorite clauses.',
  },
}
export type TabName = keyof typeof TabInfo

export default function ClauseLibrary() {
  const { t } = useTranslation()
  const { sessionInfo, access } = useContext(StoreContext)
  const {
    numFilters,
    pageNum,
    Pagination,
    loading,
    pageLoading,
    MyClausesLabel,
    error,
    query,
    setQuery,
    clauses,
    activeTab,
    setActiveTab,
    debouncedLoad,
    topOfPageRef,
    cancelFilters,
  } = useContext(ClausesContext)
  const [selectedClause, setSelectedClause] = useState<Clause | null>(null)
  const [targetTab, setTargetTab] = useState<TabIndex>()
  const [addNewClauseHidden, setAddNewClauseHidden] = useState(true)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderedList = useMemo(renderLazyLoadedClauses, [clauses, loading, pageLoading])
  const windowSize = useWindowSize()

  useContractTaskPaneViewed({
    pageTitle,
    eventDetails: [activeTab, pageNum.toString()],
  })

  return (
    <div ref={topOfPageRef}>
      <TopNav
        title={pageTitle}
        showAIBadge
        showPrimaryButton={activeTab === '1' ? access.canEditCompanyClauses() : activeTab !== '0'}
        primaryButtonTooltip={activeTab !== '0' ? 'Add New Clause' : ''}
        onPrimaryButtonClick={() => toggleAddNewClauseHidden(activeTab)}
      />

      {renderTabs()}

      <StyledStack role="tabpanel">
        <Stack.Item>
          <KeywordSearch
            label={`Search ${TabInfo[activeTab].label}`}
            placeholder="Enter Keywords"
            aria-label="Search Clauses"
            query={query}
            loading={loading}
            search={search}
          />
          <FilterButton
            isLinkDisabled={loading || !clauses || !clauses.length}
            pathName="/clauses/filters"
            numFilters={numFilters}
          />
          <StyledDivider />
        </Stack.Item>
        <Stack.Item grow={3}>{renderedList}</Stack.Item>
      </StyledStack>

      <AddNewClauseForm
        hidden={addNewClauseHidden}
        toggleHidden={toggleAddNewClauseHidden}
        targetTab={targetTab || activeTab}
        selectedClause={selectedClause}
        setSelectedClause={setSelectedClause}
      />
    </div>
  )

  function onTabClicked(activeTab?: TabIndex) {
    activeTab && setActiveTab(activeTab)
  }

  function search(query: string): void {
    setQuery(query)
    cancelFilters()
    debouncedLoad(query, activeTab)
  }

  function renderTabs() {
    if (windowSize.width < 400) {
      return (
        <StyledStack horizontal>
          <Label>{'Currently Viewing'}</Label>
          <Dropdown
            aria-label="Browse Clauses"
            onChange={(_, item) => onTabClicked(item?.key.toString() as TabIndex)}
            defaultSelectedKey={activeTab}
            disabled={loading}
            responsiveMode={ResponsiveMode.unknown}
            options={[
              { key: AllClausesTabIndex, text: AllClausesLabel },
              { key: CompanyClausesTabIndex, text: CompanyClausesLabel },
              { key: MyClausesTabIndex, text: MyClausesLabel },
            ]}
            styles={{ dropdown: { width: 175, alignItems: 'right' } }}
          />
        </StyledStack>
      )
    }

    return (
      <Pivot
        aria-label="Browse Clauses"
        onLinkClick={item => onTabClicked(item?.props.itemKey as TabIndex)}
        defaultSelectedKey={activeTab}
        selectedKey={activeTab}
      >
        <PivotItem
          ariaLabel={AllClausesLabel}
          headerText={AllClausesLabel}
          itemKey={AllClausesTabIndex}
        />
        <PivotItem
          ariaLabel={CompanyClausesLabel}
          headerText={CompanyClausesLabel}
          itemKey={CompanyClausesTabIndex}
        />
        <PivotItem
          ariaLabel={MyClausesLabel}
          headerText={MyClausesLabel}
          itemKey={MyClausesTabIndex}
        />
      </Pivot>
    )
  }

  function renderClauseContent(item: Clause) {
    const allClausesActive = activeTab === AllClausesTabIndex

    return (
      <ClauseContent
        item={item}
        addable={allClausesActive}
        editable={allClausesActive}
        activeTab={activeTab}
        access={access}
        toggleAddNewClauseHidden={toggleAddNewClauseHidden}
        setSelectedClause={setSelectedClause}
      />
    )
  }

  function renderMessage(msg: string, type = MessageBarType.error) {
    return <MessageBar messageBarType={type}>{msg}</MessageBar>
  }

  // Must call loadPage() before this render
  function renderLazyLoadedClauses() {
    if (loading) return <LoadingShimmer />
    if (error) return renderMessage(`Failed to load Clauses: ${error}`)
    if (!clauses?.length) return renderMessage(noClausesMessage(), MessageBarType.warning)

    return (
      <Pagination>
        <div style={{ minHeight: '50vh' }}>
          {clauses.map((clause, i) => {
            return (
              <div key={i}>
                {renderClauseContent(clause)}
                <StyledDivider style={{ marginTop: '1.5em', marginBottom: '0.9em' }} />
              </div>
            )
          })}
        </div>
      </Pagination>
    )
  }

  function toggleAddNewClauseHidden(tab?: TabIndex) {
    if (addNewClauseHidden) setTargetTab(tab || activeTab)
    setAddNewClauseHidden(!addNewClauseHidden)
  }

  function noClausesMessage() {
    const hasQuery = query.trim().length ? 1 : 0
    const clausesTab = parseInt(activeTab)
    const msgMatrix = [
      [
        // no query
        t('page.Clauses.No Clauses'), // on all clauses tab
        t('page.Clauses.No Company Favorites'), // on company clauses tab
        t('page.Clauses.No Favorites'), // on my clauses tab
      ],
      [
        // when a query has been entered
        `${t('page.Clauses.No Matches')} "${query}".`, // on all clauses tab
        `${t('page.Clauses.No Company Matches')} "${query}".`, // on company clauses tab
        `${t('page.Clauses.No Favorite Matches')} "${query}".`, // on my clauses tab
      ],
    ]
    return msgMatrix[hasQuery][clausesTab]
  }
}
