import StyledTextField from '@components/StyledTextField'
import { OXFormProps } from '@modules/OXForm'

export default function TextOXForm({ label }: OXFormProps) {
  return (
    <>
      <StyledTextField
        required
        label={label}
        // value={newKeyTerm?.value?.toString()}
        name="value"
        // onChange={updateStringKeyTerm}
        // errorMessage={validationError}
        multiline
      />
      {/* <CopyFromSelectionBtn onClick={copyFromSelection} disabled={copySelectionDisabled} /> */}
      <StyledTextField
        label="Notes"
        // value={newKeyTerm?.data?.notes || ''}
        name="notes"
        // onChange={updateKeyTermNotes}
        // styles={{ root: { marginTop: '1em' } }}
        multiline
      />
    </>
  )
}
