import StyledTextField from '@components/StyledTextField'
import { OXFormProps } from '@modules/OXForm'

export default function DurationDaysOXForm({ label }: OXFormProps) {
  return (
    <>
      <StyledTextField
        label={label}
        name="number"
        suffix="days"
        // onChange={updateNumericKeyTerm}
        required
        type="number"
        min="0"
        // value={removeNonNumberValues(newKeyTerm)}
        // errorMessage={validationError}
      />
      <StyledTextField
        label="Notes"
        multiline
        name="notes"
        // onChange={updateKeyTermNotes}
        // value={newKeyTerm?.data?.notes}
      />
      {/* <CopyFromSelectionBtn onClick={copyFromSelection} disabled={copySelectionDisabled} /> */}
    </>
  )
}
