import CopyFromSelectionBtn from '@components/CopyFromSelectionBtn'
import StyledTextField from '@components/StyledTextField'
import { DatePicker } from '@fluentui/react'
import { OXFormProps } from '@modules/OXForm'
import { parseShort, shortDate } from '@modules/utils'

export default function DateOXForm({ label }: OXFormProps) {
  return (
    <>
      <DatePicker
        isRequired
        label={label}
        // TODO: Find better solution. !Array.isArray(newKeyTerm?.value) was a quick fix for multiText -> date form
        // value={value}
        // onSelectDate={updateDateKeyTerm}
        formatDate={shortDate}
        placeholder="yyyy-mm-dd"
      />
      <StyledTextField
        label="Notes"
        // value={newKeyTerm?.data?.notes}
        name="notes"
        // onChange={updateKeyTermNotes}
        multiline
      />
      {/* <CopyFromSelectionBtn onClick={copyFromSelection} disabled={copySelectionDisabled} /> */}
    </>
  )
}
