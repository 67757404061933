import StyledTextField from '@components/StyledTextField'
import { ITextStyles } from '@fluentui/react'
import { OXFormProps } from '@modules/OXForm'

const styles: Partial<ITextStyles> = {
  root: { width: '100%' },
}

export default function CurrencyOXForm({ label }: OXFormProps) {
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <StyledTextField
          prefix="$"
          name="value"
          label={label}
          // onChange={updateNumericKeyTerm}
          required
          styles={styles}
          type="number"
          min="0"
          // value={newKeyTerm.value ? removeNonNumberValues(newKeyTerm) : ''}
          // errorMessage={validationError}
        />
      </div>
      <StyledTextField
        label="Notes"
        multiline
        name="notes"
        // onChange={updateKeyTermNotes}
        // value={newKeyTerm?.data?.notes ?? ''}
      />
      {/* <CopyFromSelectionBtn onClick={copyFromSelection} disabled={copySelectionDisabled} /> */}
    </>
  )
}
